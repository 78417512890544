import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import Layout from '../containers/layout';

import {responsiveTitle1} from '../components/typography.module.css';

export default function HistoryPage() {
  return (
    <Layout>
      <SEO title="Calendar" />
      <Container>
        <h1 className={responsiveTitle1}>Calendar</h1>
        <div>
          <iframe
            title="Calendar"
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=c3QuZGVtZXRyaXVzLmFrcm9uQGdtYWlsLmNvbQ&amp;color=%23039BE5&amp;showTitle=1"
            width="800"
            height="600"
          />
        </div>
      </Container>
    </Layout>
  );
}
